$logo-width-sm: 110px;
$logo-spacing-sm: 130px;
$logo-width: 178px;
$logo-spacing: 226px;
$logo-width-lg: 194px;

$nvtools-popup-bg-padding: 40px;
$nvtools-popup-width: 298px;
$nvtools-popup-width-inner: 238px;

$grid-header-md-max: "(max-width: 821px)";

@mixin header-list() {
    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        border-bottom: 1px solid #fff;

        &:first-child {
            border-top: 1px solid #fff;
        }

        a {
            display: block;
            line-height: 1.3;
            padding: 9px 0;

            &:hover {
                color: $link-color;
            }
        }
    }
}

@mixin popup-scroll() {
    .nvtools-popup-bg {
        position: fixed;
        top: 50px;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;
    }

    .nvtools-popup-inner {
        margin: 0 auto;

        @media #{$grid-lg-min}{
            max-width: 960px;
        }

        @media #{$grid-xl-min}{
            max-width: 1140px;
        }
    }
}

/*------------------------------------*\
    HEADER
\*------------------------------------*/
.page-header {
    // box-shadow: 0 3px 4px rgba(0,0,0,.2);

    @media #{$grid-md-max} {
        padding-top: 50px;
    }
}

.header-nav {
    .nav {
        columns: 4;
        column-fill: balance;

        @media #{$grid-xl-min}{
            columns: 5;
        }
    }

    .nav-item {
        display: block;
        // padding: 4px 0;
        margin-bottom: 5px;
    }

    a {
        display: block;
        font-size: 14px;
        line-height: 1.2;

        @media #{$grid-xl-min}{
            line-height: 1.7;
        }

        &:hover {
            color: $link-color;
        }

        &.active {
            color: $link-color;
        }
    }
}



/*------------------------------------*\
    Top
\*------------------------------------*/
.header-top {
    @include clearfix;
    background: $light-grey;
    transition: all 0.3s;
    box-shadow: 0 3px 4px rgba(0,0,0,0);

    @media #{$grid-md-max} {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 850;
    }

    &.sticky {
        @media #{$grid-md-max} {
            background: lighten($light-grey, 4%);
            box-shadow: 0 4px 5px rgba(0,0,0,.1);
        }
    }
}

.nvtools {
    @include clearfix;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.nvtools-item {
    position: relative;
    flex: 1 1 auto;

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        height: 34px;
        width: 1px;
        background: #fff;
        transform: translate(0, -50%);
    }

    &:first-child {
        .nvtools-btn {
            padding-left: 0;
        }
    }

    &:last-child {
        .nvtools-btn {
            padding-right: 0;
        }

        &:after {
            display: none;
        }
    }

    &.active {
        .nvtools-popup-bg {
            display: block;
        }

        .nvtools-btn {
            &:after {
                display: block;
            }
        }
    }
}

.nvtools-btn {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    color: $dark-grey;
    transition: all 0.2s;

    &:hover {
        @media #{$grid-lg-min}{
            color: $link-color;
        }
    }

    &:after {
        display: none;
        position: absolute;
        bottom: 0;
        left: 20px;
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 9px solid $menu-bg;
    }

    > strong {
        display: block;
        pointer-events: none;
        font-size: 13px;

        .icon {
            font-size: 9px;
        }
    }

    > * {
        pointer-events: none;
    }
}

.nvtools-title {
    margin-bottom: 9px;
}

.nvtools-popup-bg {
    position: absolute;
    display: none;
    font-size: 16.5px;
    z-index: 900;

    &:before {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        height: calc(100% - 50px);
        background: rgba(0, 0, 0, .3);
        z-index: 10;
        content: '';
    }
}

.nvtools-popup {
    position: absolute;
    width: $nvtools-popup-width;
    background: $menu-bg;
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 20;
}

.nvtools-content {
    padding: $nvtools-popup-bg-padding;
}

.nvtools-close {
    position: absolute;
    right: 22px;
    top: 18px;
    cursor: pointer;
    font-size: 19px;
}

.nvtools-back {
    position: absolute;
    left: 22px;
    top: 18px;
    cursor: pointer;
    font-size: 21px;

    &:before {
        @include icon;
        display: inline-block;
        content: $icon-arrow_forward_ios;
        transform: rotate(180deg);
    }
}


/*------------------------------------*\
    Account
\*------------------------------------*/
.nvtools-account {
    @include header-list;
    flex-grow: 0;

    @media #{$grid-sm-max}{
        display: none;
    }
}

.header-user-name {

}


/*------------------------------------*\
    Country and currencies
\*------------------------------------*/
.nvtools-location {
    @include popup-scroll;
    flex-grow: 0;

    @media #{$grid-sm-max}{
        display: none;
    }
}

.loc-select {
    margin-top: 5px;
    margin-bottom: 30px;
}

.loc-title {
    display: block;
    padding-bottom: 9px;
    font-weight: 700;
    border-bottom: 2px solid #fff;
}

.loc-selected {
    position: relative;
    padding: 9px 18px 9px 0;
    cursor: pointer;

    &:after {
        @include icon;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: -5px;
        transform: translate(0, -50%);
        content: $icon-arrow_forward_ios;
        pointer-events: none;
        transition: all 0.3s;
        font-size: 22px;
    }
}

.loc-submit-wrapper {
    max-width: 168px;
    font-weight: 700;
    font-size: 16px;
}

.loc-submit {
    padding: 5px 10px;
    line-height: 1.3;
    border-radius: 10px;
}

.loc-popup {
    &.active {
        .nvtools-content {
            display: none;
        }

        .loc-options {
            display: block;
        }
    }

    @media #{$grid-md-min}{
        &.country {
            .loc-options-country {
                display: block;
            }
        }

        &.currency {
            .loc-options-currency {
                display: block;
            }
        }
    }
}

.loc-content {

}

.loc-options {
    display: none;
    padding: $nvtools-popup-bg-padding;

    &.active {
        display: block;
    }

    .loc-title {
        margin-top: 14px;
    }
}

.loc-options-country,
.loc-options-currency {
    display: none;
}

.loc-currency-item,
.loc-country-item {
    display: block;
    position: relative;
    padding: 10px 0;
    cursor: pointer;
    white-space: nowrap;

    &.selected {
        font-weight: 700;

        &:before {
            @include icon;
            position: absolute;
            top: 50%;
            left: -30px;
            transform: translate(0, -50%);
            display: inline-block;
            content: $icon-check;
        }
    }
}

/*------------------------------------*\
    Categories
\*------------------------------------*/
.nvtools-categories {
    @media #{$grid-lg-min}{
        display: none;
    }

    @media #{$grid-sm-max} {
        @include popup-scroll;
    }

    .nvtools-popup {
        @media only screen and #{$grid-md-max} and #{$grid-md-min}  {
            position: fixed;
            left: 0;
            width: 100%;
        }
    }

    .nvtools-btn {
        position: relative;
        padding-left: 30px;
        color: $primary-color;

        @media #{$grid-md-min}{
            padding-left: 50px;
        }

        &:before {
            @include icon;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            display: inline-block;
            content: $icon-categories;
            font-size: 24px;

            @media #{$grid-md-min}{
                left: 20px;
            }
        }
    }
}

.nvtools-category-list {
    .nav {
        @media only screen and #{$grid-md-max} and #{$grid-md-min}  {
            columns: 3;
            column-fill: balance;
            column-gap: 24px;
        }
    }

    .nav-item {
        border-bottom: 2px solid #fff;
    }

    a {
        display: inline-block;
        padding: 9px 0 8px;
    }
}


/*------------------------------------*\
    Contact
\*------------------------------------*/
.nvtools-contact {
    text-align: center;

    @media #{$grid-md-max}{
        display: none;
    }
}


/*------------------------------------*\
    Menu
\*------------------------------------*/
.nvtools-menu {
    flex-grow: 0;

    .nvtools-popup {
        right: -65px;

        @media #{$grid-sm-max} {
            position: fixed;
            top: 50px;
            bottom: 0;
            right: 0;
            overflow-x: scroll;
        }
    }

    @media #{$grid-sm-max} {
        @include popup-scroll;
    }
}

.menu-btn {
    height: 50px;
    // font-size: 16px;
    font-size: 25px;
    line-height: 40px;
    cursor: pointer;
}

.menu-content {
    font-size: 16.5px;

    // @media #{$grid-sm-max} {
    //     position: relative;
    //     left: 0;
    //     transition: all .25s cubic-bezier(.4,0,.2,1);
    // }

    > ul {
        li {
            border-bottom: 2px solid #fff;
        }

        a {
            @media #{$grid-sm-max} {
                font-weight: 700;
            }
        }
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        display: block;
        line-height: 1.3;
        padding: 8px 0;

        &:hover {
            color: $link-color;
        }

        @media #{$grid-md-min} {
            padding: 5px 0;
            font-weight: 400;
        }
    }
}

.menu-title {
    margin: 0 (-$nvtools-popup-bg-padding);
    padding: 5px $nvtools-popup-bg-padding;
    background: #666;
    color: #fff;
    font-weight: 700;
}

.menu-popup {
    &.active {
        .menu-content {
            // left: -100%;
            display: none;
        }

        .menu-loc-popup {
            // left: 0;
            display: block;
        }

        .loc-options-country,
        .loc-options-currency,
        .menu-loc-bottom,
        .nvtools-back {
            display: block;
        }
    }
}

.menu-loc-popup {
    display: none;
    // position: absolute;
    // top: 0;
    // left: 100%;
    padding: $nvtools-popup-bg-padding $nvtools-popup-bg-padding 80px;
    transition: all .25s cubic-bezier(.4,0,.2,1);

    .nvtools-back {
        display: none;
    }

    .loc-title {
        margin-top: 14px;
    }

    .loc-options-currency {
        margin-top: 40px;
    }
}

.menu-account {
    border-bottom: 2px solid #fff;

    @media #{$grid-md-min} {
        display: none;
    }

    li {
        margin: 5px 0;
    }
}

.menu-loc {
    @include clearfix;
    border-bottom: 2px solid #fff;
    cursor: pointer;

    @media #{$grid-md-min} {
        display: none;
    }

    &-item {
        display: block;
        position: relative;
        margin: 16px 0;

        &:first-child {
            margin-top: 9px;
        }

        &:last-child {
            margin-bottom: 9px;
        }

        .icon {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            font-size: 20px;
        }
    }
}

.menu-loc-bottom {
    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    width: $nvtools-popup-width;
    padding: 20px $nvtools-popup-bg-padding;
    background: $menu-bg;
    z-index: 910;
    // box-shadow: 1px -1px 4px 1px rgba(0, 0, 0, 0.1);
}

.menu-loc-submit {
    display: block;

}

/*------------------------------------*\
    Cart
\*------------------------------------*/
.nvtools-cart {
    flex-grow: 0;
}

.nvtools-cart-btn {
    .icon-cart {
        position: relative;
        top: -1px;
        margin-left: -6px;
        font-size: 20px;
        vertical-align: middle;
    }

    span {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        background: $dark-grey;
        color: #fff;
        transition: all 0.1s;
        vertical-align: middle;
    }

    &:hover {
        .icon-cart {
            color: inherit;
        }

        span {
            background: $link-color;
        }
    }
}



/*------------------------------------*\
    Middle
\*------------------------------------*/
.header-content {
    position: relative;
    margin-top: 23px;
    margin-bottom: 23px;

    @media #{$grid-md-min} {
        margin-bottom: 28px;
        margin-top: 28px;
    }

    @media  #{$grid-md-min} and #{$grid-header-md-max} {
        margin-bottom: 0px;
    }

    @media #{$grid-lg-min} {
        margin-top: 49px;
        margin-bottom: 35px;
    }

    @media #{$grid-xs-max} {
        margin-bottom: 14px;
        margin-top: 20px;
    }
}

.header-middle {
    display: inline-block;

    @media #{$grid-lg-min} {
        position: relative;
        display: block;
        margin-bottom: 38px;
        @include clearfix;
    }
}

.header-logo {
    width: $logo-width-sm;
    display: inline-block;

    @media #{$grid-lg-min} {
        width: $logo-width;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media #{$grid-xl-min} {
        bottom: 3px;
        width: $logo-width-lg;
    }

    .logo {
        display: block;
    }
}

.header-nav {
    display: none;
    float: right;
    width: calc(100% - #{$logo-spacing});

    @media #{$grid-lg-min}{
        display: block;
    }
}


/*------------------------------------*\
    Bottom
\*------------------------------------*/
.header-bottom {
    @media #{$grid-md-max} {
        width: calc(100% - #{$logo-spacing-sm});
        float: right;
        position: relative;
        top: 8px;
    }

    @media #{$grid-sm-max} {
        top: 24px;
    }

    @media #{$grid-lg-min} {
        @include clearfix;
    }
    
    @media #{$grid-md-min} and #{$grid-header-md-max} {
        top: 0;
    }

    @media #{$grid-xs-max} {
        top: 0;
        width: 100%;
        margin-top: 15px;
    }
}

.header-bottom-section {
    // @media #{$grid-sm-max}{
    //     display: flex;
    //     flex-direction: column;
    // }

    @media #{$grid-md-min} {
        width: 43%;
        float: left;
    }

    @media  #{$grid-md-min} and #{$grid-header-md-max} {
        width: 100%;
    }

    @media #{$grid-lg-min} {
        width: 44.5%;
    }

    @media #{$grid-xl-min} {
        width: 54%;
    }

    .RadSearchBox {
        position: relative;
        width: 100%;

        .rsbInner {
            height: 24px;
            padding: 5px 5px 5px 7px;
            border: 2px solid $primary-color;
            border-radius: 10px;
            line-height: 22px;
        }

        .rsbInput {
            margin-top: 2px;
            height: 20px;
            font-style: normal;
            color: #b2b2b2;
            font-family: $font-family;
        }

        .rsbButtonSearch {
            &:hover,
            &:active {
                border: 0;
                background: transparent;
            }
        }
    }
}

.header-search {
    @include clearfix;

    // @media #{$grid-sm-max}{
    //     order: 1;
    // }
}

.header-links {
    display: inline-block;
    margin-top: 14px;
    font-size: 12px;

    @media #{$grid-md-min} {
        position: absolute;
        bottom: 53px;
        left: 54%;
    }

    @media #{$grid-md-min} and #{$grid-header-md-max} {
        bottom: 22px;
        left: unset;
        position: relative;
        text-align: right;
        display: block;
        margin-top: 0px;
    }

    @media #{$grid-lg-min} {
        left: 47%;
        bottom: 13px;
        font-size: 16.5px;
    }

    @media #{$grid-xl-min} {
        left: 57%;
    }

    @media #{$grid-xs-max} {
        margin-top: 18px;
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: inline-block;
        padding: 0 8px;

        @media #{$grid-md-min} {
            padding: 0 11px;
        }

        @media #{$grid-lg-min} {
            padding: 0 13px;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    a {
        display: block;
        padding: 5px 0;
        color: $text-light-color;

        &:hover {
            color: $link-color;
        }
    }
}

.header-contact {
    @include clearfix;
    display: block;
    font-size: 12px;
    color: $text-light-color;

    @media (max-width: 601px) {
        // margin-bottom: 7px;
        // white-space: normal;
        //display: none;
       // font-size: 10px;
    }

    @media #{$grid-md-min} and #{$grid-header-md-max}
    {
        text-align: right;
    }

    @media #{$grid-md-min} {
        margin-top: 10px;
        white-space: nowrap;
    }

    @media #{$grid-lg-min} {
        display: none;
    }

    a {
        color: $text-color2;
        font-weight: 700;

        &:hover {
            color: $link-color;
        }
    }

    .contactus_header_text_left {
        display: inline;
    }
}

.header-contact-no-wrapper {
    padding-right: 3px;
}

.header-contact-no {
    a {
        color: $text-color2;
        font-weight: 700;

        &:hover {
            color: $link-color;
        }
    }
}

/*------------------------------------*\
    Free Shipping
\*------------------------------------*/
.header-shipping {
    background: $primary-color;
    font-size: 10px;
    color: #fff;

    @media #{$grid-md-min} {
        text-align: center;
        font-size: 15px;
    }
}

.header-shipping-inner {
    position: relative;
    padding: 7px 0 6px;

    @media #{$grid-md-min} {
        padding: 4px 0 3px;
    }

    @media #{$grid-sm-max} {
        padding-right: 120px;
    }
}

.header-shipping-content {
    > span {
        font-weight: 700;
    }
}
